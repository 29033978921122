$grid: 12px;
$itemSize: 200px;
$itemSizeMobile: 130px;

.droppable {
  &__list {
    display: flex;
    width: 100%;
    min-height: $itemSize + (2 * $grid);
    margin-top: 10px;
    margin-bottom: 20px;
    padding: $grid 0;
    overflow: auto;
    background: $grey-2-color;
    &--dragging-over {
      background: $blue-11-color;
    }
  }

  &__bucket {
    position: relative;
    width: $itemSize + (2 * $grid);
    height: $itemSize + (2 * $grid);
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 15px;
    border: 2px solid $grey-4-color;
    border-radius: 5px;
    background: $grey-2-color;
    text-align: center;
    &--dragging-over {
      background: $blue-11-color;
    }
    &-volume {
      $size: 30px;
      position: absolute;
      bottom: -1 * $size / 2;
      right: -1 * $size / 2;
      width: $size;
      height: $size;
      border: none;
      border-radius: $size;
      background: $red-7-color;
      color: white;
      outline: none;
      line-height: $size;
      text-align: center;
    }
  }



  &__item {
    position: relative;
    flex: 0 0 $itemSize;
    width: $itemSize;
    height: $itemSize;
    padding-left: $grid;
    opacity: 1;
    user-select: none;
    background: none;
    &--dragging {
      opacity: 0.7;
    }
  }

  &__item-delete {
    $delete-size: 28px;
    $x-width: 3px;
    &:before, &:after {
      position: absolute;
      top: ($delete-size / 2) - ($x-width / 2);
      left: (0.35 * $delete-size) / 2;
      content: ' ';
      width: 0.65 * $delete-size;
      height: $x-width;
      background-color: white;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    position: absolute;
    top: -0.35 * $delete-size;
    right: -0.35 * $delete-size;
    width: $delete-size;
    height: $delete-size;
    padding: 0;
    border: none;
    border-radius: $delete-size;
    background: $red-7-color;
    color: white;
    outline: none;
    line-height: $delete-size;
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .droppable {
    &__list {
      min-height: $itemSizeMobile + (2 * $grid);
    }

    &__bucket {
      width: $itemSizeMobile + (2 * $grid);
      height: $itemSizeMobile + (2 * $grid);
    }

    &__item {
      flex: 0 0 $itemSizeMobile;
      width: $itemSizeMobile;
      height: $itemSizeMobile;
    }
  }
}
