.player-questionnaire {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 $default-side-padding;
  }

  &__question-container {
    width: 100%;
    white-space: pre-wrap;
  }

  &__answers-container {
    width: 100%;
  }

  &__dnd-container {
    width: 100%;
  }

  &____action-buttons {
    margin-top: 40px;
  }
}

.questionnaire-result {
  &__modal {
    max-width: 550px;
  }
  &__container {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    border-radius: 7px;
    background: white;
  }
  &__header {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    color: $highlight-color;
  }
  &__text {
    width: 100%;
    margin-bottom: 20px;
    color: black;
    font-size: 17px;
    white-space: pre-wrap;
  }
  &__nav-buttons {
    align-self: center;
    margin-top: 10px;
    margin-bottom: 20px;
    & > .btn-continue {
      height: 40px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 850px) {
  .player-questionnaire {
    &__container {
      padding: 0 $default-mobile-side-padding;
    }
  }
}
