$transition-time: 300ms;

.material-input {
  position: relative;
  margin: 35px 0 27px 0;

  *,
  :before,
  :after {
    box-sizing: border-box;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    font-size:16px;
    line-height:130%;
    padding: 14px 10px 14px 5px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $blue-grey-3-color;
    background: none;
    color: $grey-5-color;
    resize: none;

    &:disabled {
      color: $blue-grey-5-color;
    }
    &:focus {
      outline: none;
    }
    &:required {
      box-shadow: none;
    }

    &:disabled~label {
      color: $grey-4-color;
    }
    &.filled~label,
    &:focus~label,
    &:valid~label {
      left: 4px;
      top: -14px;
      font-size: 12px;
      color: #8c8c8c;
    }

  }

  &--required {
    input,
    textarea {
      &:invalid {
        border-color: $red-7-color;
        &::placeholder {
          color: $red-7-color;
          opacity: 1;
        }
      }
    }
  }

  label {
    position: absolute;
    left: 5px;
    top: 20px;
    color: #88b9de;
    font-size: 16px;
    font-weight: normal;
    pointer-events: none;
    transition: $transition-time ease all;
  }

  textarea + label {
    top:10px;
    left: 5px;
  }

  &__messages--error {
    color: $red-7-color
  }
}
