.player-preview {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    max-width: 1100px;
    height: 100%;
  }

  &__dimensions-container {
    display: flex;
    flex: 0 0 auto;
    flex-flow: wrap;
    justify-content: center;
  }

  &__dimension-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 135px;
    color: white;
    &--active {
      color: $blue-11-color;
    }
    &:focus {
      outline: none;
    }
  }

  &__dimension-icon {
    width: 60px;
    height: 60px;
    text-align: center;
    font-size: 48px;
    &--rotated {
      transform: rotate(90deg);
    }
  }

  &__dimension-title {
    padding: 10px;
  }

  &__iframe-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    margin: 24px 0;
    overflow: auto;
  }

  &__action-buttons-container {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
