.player-points-column {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 30px 0;

    &::before,
    &::after {
      content: '';
      flex: 1 0 40px;
    }
  }

  &__winning-streak {
    position: absolute;
    top: 30px;
    left: 12px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: $highlight-color;
    color: white;
    text-align: center;
    line-height: 27px;
    font-size: 12px;
    font-weight: 700;
    &::before {
      content: '+';
    }
    &:after {
      content: '!';
    }
  }

  &__progress-container {
    position: relative;
    flex: 0 1 400px;
    width: 10px;
    height: 100%;
    margin: 0 20px;
    border-radius: 5px;
    background: $background-color;
  }

  &__progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: $highlight-color;
    transition: height 50ms linear;
  }

  &__points-label-anchor {
    position: absolute;
    top: 0;
    left: 50%;
  }

  &__points-label {
    position: relative;
    top: -10px;
    left: -50%;
    padding: 0 4px;
    height: 20px;
    border-radius: 13px;
    background: $highlight-color;
    box-shadow: $box-shadow;
    color: white;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
  }
}
@media screen and (min-width: 850px) {
  .player-points-column {
    &__container {
      &::before,
      &::after {
        content: '';
        flex: 1 0 70px;
      }
    }
    &__progress-container {
      width: 25px;
    }
    &__points-label {
      height: 25px;
      padding: 0 10px;
      line-height: 25px;
      font-size: 14px;
    }
    &__winning-streak {
      top: 42px;
      left: 10px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
    }
  }
}
