.material-tabs {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $blue-grey-6-color;
  }

  &__tab-title {
    padding: 0 15px;
    font-size: 14px;
    line-height: 45px;
    cursor: pointer;
    &.active {
      border-bottom: 3px solid $blue-11-color;
    }
    &:focus {
      outline: none;
    }
  }

  &__content {
    width:  100%;
    margin-top: 20px;
  }
}
