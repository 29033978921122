$md-radio-checked-color: $highlight-color;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 7px;
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(223, 5, 43, 0.0);
  }
  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(223, 5, 43, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(223, 5, 43, 0);
  }
}

.md-radio {
  margin: 16px 0;
  &.md-radio-inline {
    display: inline-block;
  }
  input[type="radio"] {
    display: none;
    &:checked+label {
      background: $answer-selected-background;
      color: $highlight-color;
    }
    &:checked+label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }
    &:checked+label:after {
      transform: scale(1);
    }
    &:disabled+label {
      cursor: unset;
    }
  }
  label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    min-height: $md-radio-size;
    margin-bottom: 0;
    padding: 15px 30px 15px 50px;
    border-radius: 22px;
    box-shadow: $box-shadow;
    background: $answer-background;
    line-height: 20px;
    vertical-align: bottom;
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 15px;
      top: 15px;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }
    &:after {
      top: 15px + ($md-radio-size / 2 - $md-radio-checked-size / 2);
      left: 15px + ($md-radio-size / 2 - $md-radio-checked-size / 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}

.radio-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.radio-image {
  flex: 0 0 50%;
  max-width: 250px;
  height: 250px;
  padding: 10px;
  input {
    position: absolute;
    left: -9999px;
  }
  label {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    & .radio-image__file-preview-container {
      display: block;
      width: 100%;
      height: 100%;
      &::before,
      &::after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all .3s ease;
        transition-property: transform, border-color;
      }
      &::before {
        z-index: 1001;
        top: 10px;
        left: 10px;
        width: $md-radio-size;
        height: $md-radio-size;
        background: white;
        border: 2px solid $md-radio-border-color;
      }
      &::after {
        z-index: 1002;
        top: 10px+($md-radio-size / 2 - $md-radio-checked-size / 2);
        left: 10px+($md-radio-size / 2 - $md-radio-checked-size / 2);
        width: $md-radio-checked-size;
        height: $md-radio-checked-size;
        transform: scale(0);
        background: $md-radio-checked-color;
      }
    }
  }

  input:checked + label .radio-image__file-preview-container {
    box-shadow: 0 0 3px 3px $answer-selected-background;
    &::before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }
    &::after {
      transform: scale(1);
    }
  }
}

@media screen and (max-width: 550px) {
  .radio-image {
    height: 200px;
  }
}
@media screen and (max-width: 500px) {
  .radio-image {
    height: 170px;
  }
}
@media screen and (max-width: 450px) {
  .radio-image {
    height: 195px;
  }
}
@media screen and (max-width: 400px) {
  .radio-image {
    height: 160px;
    width: 160px;
  }
}
