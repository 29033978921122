.player-page {
  &__wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &__init-status {
    width: 100%;
    padding: 20px 10px;
    text-align: center;
    font-size: 18px;
  }
}
