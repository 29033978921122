$md-checkbox-margin: 16px 0;
$md-checkbox-checked-color: rgb(51, 122, 183);
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-size: 20px;
$md-checkbox-padding: 4px;
$md-checkbox-ripple-size: 4px;
$md-checkmark-width: 2px;
$md-checkmark-color: $highlight-color;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(223, 5, 43, 0.0);
  }
  50% {
    box-shadow: 0px 0px 0px $md-checkbox-ripple-size rgba(223, 5, 43, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $md-checkbox-ripple-size rgba(223, 5, 43, 0);
  }
}

.md-checkbox {
  margin: $md-checkbox-margin;
  &.md-checkbox-inline {
    display: inline-block;
  }
  input[type="checkbox"] {
    display: none;
    &:checked {
      +label {
        background: $answer-selected-background;
        color: $highlight-color;
      }
      +label:before {
        border-color: $md-checkmark-color;
        animation: ripple 0.2s linear forwards;
      }
      +label:after {
        $md-checkmark-size: $md-checkbox-size - 2*$md-checkbox-padding;
        transform: rotate(-45deg);
        top: 15px + ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
        left: 15px + $md-checkbox-padding;
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;
        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      }
    }
    &:disabled {
      &,
      &+label {
        cursor: default;
      }
      +label:before {
        border-color: $md-checkbox-border-color-disabled;
      }
      &:checked {
        +label:before {
          background: $md-checkbox-checked-color-disabled;
        }
      }
    }
  }
  label {
    $vertical-padding: 15px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    min-height: $md-checkbox-size + (2 * $vertical-padding);
    margin-bottom: 0;
    padding: $vertical-padding 30px $vertical-padding 50px;
    border-radius: 22px;
    box-shadow: $box-shadow;
    background: $answer-background;
    line-height: 20px;
    vertical-align: bottom;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 15px;
      top: 15px;
    }
    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: 2px solid $md-checkbox-border-color;
      border-radius: 2px;
      cursor: pointer;
      transition: background .3s;
    }
  }
}

.checkbox-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.checkbox-image {
  flex: 0 0 50%;
  max-width: 250px;
  height: 250px;
  padding: 10px;

  input {
    position: absolute;
    left: -9999px;
  }

  label {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    & .checkbox-image__file-preview-container {
      display: block;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
        position: absolute;
        z-index: 1001;
        top: 10px;
        left: 10px;
        width: $md-checkbox-size;
        height: $md-checkbox-size;
        border: $md-checkmark-width solid $md-checkbox-border-color;
        border-radius: 2px;
        background: white;
      }
    }
  }

  input:checked+label .checkbox-image__file-preview-container {
    box-shadow: 0 0 3px 3px $answer-selected-background;

    &::before {
      border-color: $highlight-color;
      animation: ripple 0.2s linear forwards;
    }
    &::after {
      $md-checkmark-size: $md-checkbox-size - 2*$md-checkbox-padding;
      content: '';
      position: absolute;
      z-index: 1002;
      top: 10px + ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
      left: 10px + $md-checkbox-padding;
      width: $md-checkmark-size;
      height: $md-checkmark-size / 2;
      border: $md-checkmark-width solid $md-checkmark-color;
      border-top-style: none;
      border-right-style: none;
      transform: rotate(-45deg);
    }
  }
}

@media screen and (max-width: 550px) {
  .checkbox-image {
    height: 200px;
  }
}
@media screen and (max-width: 500px) {
  .checkbox-image {
    height: 170px;
  }
}
@media screen and (max-width: 450px) {
  .checkbox-image {
    height: 145px;
  }
}
@media screen and (max-width: 400px) {
  .checkbox-image {
    height: 120px;
  }
}
