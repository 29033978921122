.player-paragraph {
  &__container {
    padding: 0 $default-side-padding;
    font-size: 15px;
    line-height: 18px;
    white-space: pre-wrap;
  }
}

@media screen and (max-width: 850px) {
  .player-paragraph {
    &__container {
      padding: 0 $default-mobile-side-padding;
      font-size: 13px;
      line-height: 15px;
    }
  }
}
