i.big {
  font-size: 48px;
}

i.normal {
  font-size: 24px;
}

i.white {
  color: white;
}

i.darkgrey {
  color: $blue-grey-11-color;
}
