.app {
  &__header {
    position: relative;
    height: 70px;
    background: $blue-grey-11-color;
    h1 {
      margin: 0 30px;
      padding: 0;
      color: white;
      line-height: 70px;
    }
  }
  &__content-wrapper {
    position: relative;
    flex: 1 1 auto;
  }
}

#modal-root > .transition.visible {
  display: flex !important;
}
