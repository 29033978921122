.course-sections-editors {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: visible;
    align-items: center;
    flex-direction: column;
    clear: both;

    .content {
      width: 100%;
      max-width: 800px;
      height: auto;
      padding: 30px;
      padding-top: 50px;
    }
  }

  &__header {
    width: 100%;
    margin-bottom: 40px;
  }
}
.ql-bubble {
  button {
    box-shadow: none !important;
  }
}
.picture-slides-editor {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    margin-bottom: 40px;
  }

  &__empty-list-label {
    font-size: 18px;
    text-align: center;
  }

  &__row {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 30px;
  }

  &__slide-number {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    line-height: 20px;
    text-align: center;
    background-color: $blue-grey-1-color;
  }

  &__slide-drag-handle {
    position: absolute;
    top: 40px;
    left: 18px;
  }

  &__slide-image-container {
    flex: 0 0 20px;
    border-radius: 10px;
    background-color: 'black';
    margin-right: 15px;
    .form-select {
      padding: 0;
    }
  }

  &__slide-image-dropzone {
    height: 36px;
    width: 36px;
    border-radius: 36px;
    background-color: #272727;
    text-align: center;
    font-size: 14px;
    padding-top: 7px;
    color: white;
  }

  &__slide-image-remove {
    margin-top: 4px;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    color: white;
    border: 4px solid #272727;
    background-color: #272727;
    padding-top: 4px;
    padding-left: 6px;
    align-items: center;
    justify-items: center;
  }

  &__slide-top-image {
    justify-content: center;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }

  &__slide-bottom-image {
    justify-content: center;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }

  &__slide-image-settings {
    position: absolute;
    margin-top: 12px;
    padding: 20px;
    margin-left: -64px;
    border-radius: 25px;
    background-color: #444;
    color: white;
    z-index: 10;
    &__arrow {
      position: absolute;
      width: 0;
      height: 0;
      top: -7px;
      left: 44%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #444;
    }
    &__remove {
      margin-top: 15px;
      margin-bottom: 10px;
      svg {
        margin-right: 5px;
      }
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
  }

  .ql-editor {
    padding: 0;
  }
  &__slide-image-colorpicker {
    margin-top: 4px;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    border: 4px solid #272727;
    background-color: transparent;
  }

  &__slide-image-additems {
    margin-top: 4px;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    color: white;
    padding-top: 4px;
    padding-left: 6px;
    border: 4px solid #272727;
    background-color: #272727;
  }

  &__add-image-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    border-radius: 7px;
    box-shadow: $box-shadow;
    background-color: $blue-grey-11-color;
    line-height: 75px;
    text-align: center;
  }

  &__slide-image {
    display: inline-block;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: middle;
  }

  &__slide-titles-container {
    position: relative;
    display: flex;
    justify-content: center;
    border: 1px solid gray;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
  }

  &__slide-title-main {
    font-size: 2em;
    padding: 10px;
    line-height: 120%;
    padding-left: 10px;
    margin-bottom: 10px;
    z-index: 2;
    color: white;
    border: 0 !important;
    margin-left: 10px;
    margin-right: 10px;
    &.bold-attr {
      font-weight: 600;
    }
    &.bold-italic-attr {
      font-weight: 600;
      font-style: italic;
    }
    &.italic-attr {
      font-style: italic;
    }
    &.arial-font {
      font-family: 'Arial';
    }
    &.verdana-font {
      font-family: 'Verdana';
    }
    &.times-new-roman-font {
      font-family: 'Times New Roman';
    }
    &.courier-font {
      font-family: 'Courier';
    }
    &.worksans-font {
      font-family: 'Work Sans';
    }
    &.avenirnext-font {
      font-family: 'Avenir Next';
    }
    &.inter-font {
      font-family: 'Inter';
    }
    &.fit {
      width: fit-content;
      margin-left: 10px;
      margin-right: 10px;
    }

    &.stretch {
      margin-left: 0px;
      margin-right: 0px;
    }
    .hidden {
      position: absolute;
      left: -1000px;
    }
    .visible {
      position: inherit;
      left: inherit;
    }

    &:hover {
      border: 1px dashed #91d5ff;
    }
  }

  &__slide-title-main-add {
    position: absolute;
    margin-top: -80px;
    margin-left: -110px;
    color: #fff;
    line-height: 30px;
    &:hover {
      color: #666;
    }
    .addbtn {
      width: 30px;
      line-height: 30px;
      float: right;
      margin-left: 6px;
      height: 30px;
      color: white;
      text-align: center;
      border-radius: 30px;
      background-color: #444;
    }
  }

  &__slide-title-secondary {
    color: $blue-grey-9-color;
    font-size: 1.2em;
    margin-left: 10px;
    margin-right: 10px;
    padding: 1px;
    z-index: 2;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    ul {
      color: inherit;
      font-size: 1.1rem;
    }
    h1 {
      font-size: 2.2rem;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }
    &:hover {
      padding: 0px;
      border: 1px dashed #91d5ff;
      &.settings {
        display: block;
      }
    }

    .settings {
      position: absolute;
      margin-top: 10px;
      z-index: 11;
      margin-left: -60px;
      color: #fff;
      line-height: 30px;
      &:hover {
        color: #666;
      }
      .toolbar {
        position: absolute;
        background-color: #444;
        width: 410px;
        flex: 1;
        flex-direction: row;
        padding-left: 30px;
        margin-left: 30px;
        border-radius: 30px;
        height: 40px;
        padding-top: 5px;
        margin-left: 15px;
        svg {
          margin-right: 10px;
        }
        .txtColor {
          float: left;
          width: 120px;
          color: white;
          font-size: 10px;
        }
        .colorBtn {
          border-radius: 20px;
          width: 20px;
          height: 20px;
          margin-top: 6px;
          margin-right: 10px;
          border: 3px solid black;
          float: left;
        }
        .txtAlign {
          border-radius: 10px;
          width: 100px;
          height: 10px;
          border-width: 2px;
          float: left;
          color: white;
          flex: 1;
          flex-direction: row;
        }
      }
      .addbtn {
        width: 30px;
        line-height: 30px;
        float: right;
        margin-left: 6px;
        height: 30px;
        color: white;
        text-align: center;
        border-radius: 30px;
        background-color: #444;
      }
    }
  }

  &__slide-action-buttons {
    flex: 0 0 auto;
    display: none;
    color: white;
  }

  &__action-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;
  }
}
.questionnaire-section-editor {
  &__container {
    width: 100%;
    margin-bottom: 40px;
  }

  &__question-header {
    margin-top: 20px;
  }

  &__questions-list {
    margin-top: 40px;
  }

  &__question-row {
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin: 11px 0;
  }

  &__actions-buttons-row {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    padding: 0 15px;
  }

  &__settings-subsection {
    margin: 0;
  }

  &__question-content {
    flex: 1 1 auto;
  }

  &__image-container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 210px;
  }

  &__dropzone {
    width: 200px;
    height: 200px;
    padding: 40px 20px;
    border: 1px dashed $grey-3-color;
    background-color: $grey-1-color;
    text-align: center;
    border-radius: 10px;
    font-size: 16px;
  }

  &__image {
    width: 200px;
    height: 200px;
  }

  &__question-is-correct {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    flex-direction: column;
    justify-content: center;
    font-size: 0.75em;
    margin-top: 19px;
    color: #888;
    margin-right: -15px;
  }

  &__question-action-buttons {
    position: absolute;
    display: none;
    right: -11px;
  }

  &__question-drag-handle {
    display: flex;
    align-items: space-around;
    color: #888;
    flex: 0 1 30px;
    justify-content: center;
    cursor: pointer;
  }

  &__input-container {
    width: 100%;
    clear: both;
    padding: 0 7px;
  }

  &__settings-container {
    width: 100%;
    clear: both;
    padding: 0 7px;
  }

  &__settings-row {
    margin: 25px 0 40px;
  }
}
.content-section-editor {
  &__container {
    width: 100%;
    h3 {
      margin-bottom: 40px;
    }
  }
  &__imagepicker {
    width: 400px;
    min-height: 100px;
    position: absolute;
    z-index: 100;
    background-color: white;
    border-radius: 10px;
    border: 1px solid gray;
    box-shadow: 1px 2px 4px #ccc;
    padding: 20px;
  }
}
.text-section-editor {
  &__container {
    width: 100%;
    margin-bottom: 40px;
    h3 {
      margin-bottom: 40px;
    }
  }
}
.video-editor {
  &__container {
    width: 100%;
    margin-bottom: 40px;
  }

  &__dropzone {
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    padding-top: 100px;
    border: 1px dashed $grey-3-color;
    background-color: $grey-1-color;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
  }

  &__video-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    border: 1px dashed $grey-3-color;
    background-color: $grey-1-color;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
  }

  &__video {
    max-width: 100%;
    max-height: 400px;
  }

  &__video-file-status {
    display: flex;
    height: 150px;
    font-size: 18px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }

  &__poster-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    clear: both;
    height: 250px;
  }

  &__poster {
    max-width: 100%;
    height: 100%;
  }

  &__poster-file-status {
    display: flex;
    height: 150px;
    font-size: 18px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }

  &__input-container {
    width: 100%;
    margin: 49px 0;
  }
}

.picture-editor {
  &__container {
    width: 100%;
    margin-bottom: 40px;
  }

  &__dropzone {
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    padding-top: 55px;
    border: 1px dashed $grey-3-color;
    background-color: $grey-1-color;
    text-align: center;
    height: 130px;
    border-radius: 10px;
    font-size: 16px;
  }

  &__image-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    clear: both;
  }

  &__image {
    max-width: 100%;
    height: 100%;
  }

  &__image-file-status {
    display: flex;
    height: 150px;
    font-size: 18px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }
}

.redirections-section-editor {
  &__container {
    width: 100%;
    margin-bottom: 40px;

    .form-select {
      padding-top: 0;
    }
  }
}

.speed-course-settings {
  &__container {
    width: 100%;
    margin-bottom: 40px;
    h3 {
      margin-bottom: 40px;
    }
  }

  &__section {
    width: 100%;
  }

  &__input-wrapper {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
  }

  &__section-header {
    align-self: flex-start;
    margin: 10px;
    padding-left: 5px;
    color: $blue-grey-11-color;
    font-size: 16px;
  }

  &__dropzone-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 320px;
    padding: 10px;
  }

  &__dropzone-image {
    height: 240px;
    width: 100%;
  }

  &__dropzone-buttons {
    flex: 0 0 60px;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    button {
      height: 40px;
    }
  }

  &__cover-file-status {
    width: 100%;
    border-width: 2px;
    padding: 40px 20px;
    height: 200px;
    border-radius: 5px;
    background-color: #fafafa;
    border: 1px dotted #ccc;
    text-align: center;
  }
}
