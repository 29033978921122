.form-switch {
  &__container {
    display: flex;
    justify-content: center;
    margin-top: 45px;
    padding: 0 5px;
  }

  &__label {
    flex: 1 1 auto;
    font-size: 16px;
    color: #37475f;
  }

  &__switch {
    flex: 0 0 auto;
  }
}

