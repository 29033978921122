
$tablet-large: 1279px;
$tablet: 959px;
$mobile: 719px;
$mobile-small: 479px;

$sidebar-width: 200px;

$box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);


// player variables

$nav-bar-height: 70px;
$nav-bar-font-size: 20px;

$default-side-padding: 25px;
$default-mobile-side-padding: 7px;

$highlight-color: #DF052B;
$text-color: #3F3F3F;
$background-color: #F5F5F5;

$answer-background: #F7F7F7;
$answer-selected-background: #FECA2B;

$button-continue-background-color: #DF052B;
$button-continue-text-color: #FECA2B;
$button-continue-disabled-background-color: #767676;
$button-continue-disabled-text-color: #BC9F49;

$button-start-background-color: #DF052B;
$button-start-text-color: #FED130;

$finalize-button-background-color: #DF052B;
$finalize-button-text-color: #FED130;

$progress-bar-color: #FED130;
$progress-bar-text-color: grey;

// player variables end

$yellow-1-color: #FFFDE7;
$yellow-2-color: #FFF9C4;
$yellow-3-color: #FFF59D;
$yellow-4-color: #FFF176;
$yellow-5-color: #FFEE58;
$yellow-6-color: #FFEB3B;
$yellow-7-color: #FDD835;
$yellow-8-color: #FBC02D;
$yellow-9-color: #F9A825;
$yellow-10-color: #F57F17;

$amber-1-color: #FFF8E1;
$amber-2-color: #FFECB3;
$amber-3-color: #FFE082;
$amber-4-color: #FFD54F;
$amber-5-color: #FFCA28;
$amber-6-color: #FFC107;
$amber-7-color: #FFB300;
$amber-8-color: #FFA000;
$amber-9-color: #FF8F00;
$amber-10-color: #FF6F00;

$orange-1-color: #FFF3E0;
$orange-2-color: #FFE0B2;
$orange-3-color: #FFCC80;
$orange-4-color: #FFB74D;
$orange-5-color: #FFA726;
$orange-6-color: #FF9800;
$orange-7-color: #FB8C00;
$orange-8-color: #F57C00;
$orange-9-color: #EF6C00;
$orange-10-color: #E65100;

$deep-orange-1-color: #FBE9E7;
$deep-orange-2-color: #FFCCBC;
$deep-orange-3-color: #FFAB91;
$deep-orange-4-color: #FF8A65;
$deep-orange-5-color: #FF7043;
$deep-orange-6-color: #FF5722;
$deep-orange-7-color: #F4511E;
$deep-orange-8-color: #E64A19;
$deep-orange-9-color: #D84315;
$deep-orange-10-color: #BF360C;

$red-1-color: #FFEBEE;
$red-2-color: #FFCDD2;
$red-3-color: #EF9A9A;
$red-4-color: #E57373;
$red-5-color: #EF5350;
$red-6-color: #F44336;
$red-7-color: #E53935;
$red-8-color: #D32F2F;
$red-9-color: #C62828;
$red-10-color: #B71C1C;

$brown-1-color: #EFEBE9;
$brown-2-color: #D7CCC8;
$brown-3-color: #BCAAA4;
$brown-4-color: #A1887F;
$brown-5-color: #8D6E63;
$brown-6-color: #795548;
$brown-7-color: #6D4C41;
$brown-8-color: #5D4037;
$brown-9-color: #4E342E;
$brown-10-color: #3E2723;

$green-1-color: #E8F5E9;
$green-2-color: #C8E6C9;
$green-3-color: #A5D6A7;
$green-4-color: #81C784;
$green-5-color: #66BB6A;
$green-6-color: #4CAF50;
$green-7-color: #43A047;
$green-8-color: #388E3C;
$green-9-color: #2E7D32;
$green-10-color: #1B5E20;

$light-green-1-color: #EFEBE9;
$light-green-2-color: #D7CCC8;
$light-green-3-color: #BCAAA4;
$light-green-4-color: #A1887F;
$light-green-5-color: #8D6E63;
$light-green-6-color: #795548;
$light-green-7-color: #6D4C41;
$light-green-8-color: #5D4037;
$light-green-9-color: #4E342E;
$light-green-10-color: #3E2723;

$lime-1-color: #F9FBE7;
$lime-2-color: #F0F4C3;
$lime-3-color: #E6EE9C;
$lime-4-color: #DCE775;
$lime-5-color: #D4E157;
$lime-6-color: #CDDC39;
$lime-7-color: #C0CA33;
$lime-8-color: #AFB42B;
$lime-9-color: #9E9D24;
$lime-10-color: #827717;

$teal-1-color: #E0F2F1;
$teal-2-color: #B2DFDB;
$teal-3-color: #80CBC4;
$teal-4-color: #4DB6AC;
$teal-5-color: #26A69A;
$teal-6-color: #009688;
$teal-7-color: #00897B;
$teal-8-color: #00796B;
$teal-9-color: #00695C;
$teal-10-color: #004D40;

$cyan-1-color: #E0F7FA;
$cyan-2-color: #B2EBF2;
$cyan-3-color: #80DEEA;
$cyan-4-color: #4DD0E1;
$cyan-5-color: #26C6DA;
$cyan-6-color: #00BCD4;
$cyan-7-color: #00ACC1;
$cyan-8-color: #0097A7;
$cyan-9-color: #00838F;
$cyan-10-color: #006064;

$light-blue-1-color: #E1F5FE;
$light-blue-2-color: #B3E5FC;
$light-blue-3-color: #81D4FA;
$light-blue-4-color: #4FC3F7;
$light-blue-5-color: #29B6F6;
$light-blue-6-color: #03A9F4;
$light-blue-7-color: #039BE5;
$light-blue-8-color: #0288D1;
$light-blue-9-color: #0277BD;
$light-blue-10-color: #01579B;

$blue-1-color: #E3F2FD;
$blue-2-color: #BBDEFB;
$blue-3-color: #90CAF9;
$blue-4-color: #64B5F6;
$blue-5-color: #42A5F5;
$blue-6-color: #2196F3;
$blue-7-color: #1E88E5;
$blue-8-color: #1976D2;
$blue-9-color: #1565C0;
$blue-10-color: #0D47A1;
$blue-11-color: #5cabec;

$btn-blue: #007AFF;

$indigo-1-color: #E8EAF6;
$indigo-2-color: #C5CAE9;
$indigo-3-color: #9FA8DA;
$indigo-4-color: #7986CB;
$indigo-5-color: #5C6BC0;
$indigo-6-color: #3F51B5;
$indigo-7-color: #3949AB;
$indigo-8-color: #303F9F;
$indigo-9-color: #283593;
$indigo-10-color: #1A237E;

$deep-purple-1-color: #EDE7F6;
$deep-purple-2-color: #D1C4E9;
$deep-purple-3-color: #B39DDB;
$deep-purple-4-color: #9575CD;
$deep-purple-5-color: #7E57C2;
$deep-purple-6-color: #673AB7;
$deep-purple-7-color: #5E35B1;
$deep-purple-8-color: #512DA8;
$deep-purple-9-color: #4527A0;
$deep-purple-10-color: #311B92;

$blue-grey-1-color: #ECEFF1;
$blue-grey-2-color: #CFD8DC;
$blue-grey-3-color: #B0BEC5;
$blue-grey-4-color: #90A4AE;
$blue-grey-5-color: #78909C;
$blue-grey-6-color: #607D8B;
$blue-grey-7-color: #546E7A;
$blue-grey-8-color: #455A64;
$blue-grey-9-color: #37474F;
$blue-grey-10-color: #263238;
$blue-grey-11-color: #37475f;


$grey-1-color: #fafafa;
$grey-2-color: #efefef;
$grey-3-color: #cccccc;
$grey-4-color: #666666;
$grey-5-color: #333333;

// scss
:export {
  tabletlarge: $tablet-large;
  tablet: $tablet;
  mobile: $mobile;
  mobilesmall: $mobile-small;

  sidebarwidth: $sidebar-width;

  boxshadow: $box-shadow;

  yellow1color: $yellow-1-color;
  yellow2color: $yellow-2-color;
  yellow3color: $yellow-3-color;
  yellow4color: $yellow-4-color;
  yellow5color: $yellow-5-color;
  yellow6color: $yellow-6-color;
  yellow7color: $yellow-7-color;
  yellow8color: $yellow-8-color;
  yellow9color: $yellow-9-color;
  yellow10color: $yellow-10-color;

  amber1color: $amber-1-color;
  amber2color: $amber-2-color;
  amber3color: $amber-3-color;
  amber4color: $amber-4-color;
  amber5color: $amber-5-color;
  amber6color: $amber-6-color;
  amber7color: $amber-7-color;
  amber8color: $amber-8-color;
  amber9color: $amber-9-color;
  amber10color: $amber-10-color;

  orange1color: $orange-1-color;
  orange2color: $orange-2-color;
  orange3color: $orange-3-color;
  orange4color: $orange-4-color;
  orange5color: $orange-5-color;
  orange6color: $orange-6-color;
  orange7color: $orange-7-color;
  orange8color: $orange-8-color;
  orange9color: $orange-9-color;
  orange10color: $orange-10-color;

  deeporange1color: $deep-orange-1-color;
  deeporange2color: $deep-orange-2-color;
  deeporange3color: $deep-orange-3-color;
  deeporange4color: $deep-orange-4-color;
  deeporange5color: $deep-orange-5-color;
  deeporange6color: $deep-orange-6-color;
  deeporange7color: $deep-orange-7-color;
  deeporange8color: $deep-orange-8-color;
  deeporange9color: $deep-orange-9-color;
  deeporange10color: $deep-orange-10-color;

  red1color: $red-1-color;
  red2color: $red-2-color;
  red3color: $red-3-color;
  red4color: $red-4-color;
  red5color: $red-5-color;
  red6color: $red-6-color;
  red7color: $red-7-color;
  red8color: $red-8-color;
  red9color: $red-9-color;
  red10color: $red-10-color;

  brown1color: $brown-1-color;
  brown2color: $brown-2-color;
  brown3color: $brown-3-color;
  brown4color: $brown-4-color;
  brown5color: $brown-5-color;
  brown6color: $brown-6-color;
  brown7color: $brown-7-color;
  brown8color: $brown-8-color;
  brown9color: $brown-9-color;
  brown10color: $brown-10-color;

  green1color: $green-1-color;
  green2color: $green-2-color;
  green3color: $green-3-color;
  green4color: $green-4-color;
  green5color: $green-5-color;
  green6color: $green-6-color;
  green7color: $green-7-color;
  green8color: $green-8-color;
  green9color: $green-9-color;
  green10color: $green-10-color;

  lightgreen1color: $light-green-1-color;
  lightgreen2color: $light-green-2-color;
  lightgreen3color: $light-green-3-color;
  lightgreen4color: $light-green-4-color;
  lightgreen5color: $light-green-5-color;
  lightgreen6color: $light-green-6-color;
  lightgreen7color: $light-green-7-color;
  lightgreen8color: $light-green-8-color;
  lightgreen9color: $light-green-9-color;
  lightgreen10color: $light-green-10-color;

  lime1color: $lime-1-color;
  lime2color: $lime-2-color;
  lime3color: $lime-3-color;
  lime4color: $lime-4-color;
  lime5color: $lime-5-color;
  lime6color: $lime-6-color;
  lime7color: $lime-7-color;
  lime8color: $lime-8-color;
  lime9color: $lime-9-color;
  lime10color: $lime-10-color;

  teal1color: $teal-1-color;
  teal2color: $teal-2-color;
  teal3color: $teal-3-color;
  teal4color: $teal-4-color;
  teal5color: $teal-5-color;
  teal6color: $teal-6-color;
  teal7color: $teal-7-color;
  teal8color: $teal-8-color;
  teal9color: $teal-9-color;
  teal10color: $teal-10-color;

  cyan1color: $cyan-1-color;
  cyan2color: $cyan-2-color;
  cyan3color: $cyan-3-color;
  cyan4color: $cyan-4-color;
  cyan5color: $cyan-5-color;
  cyan6color: $cyan-6-color;
  cyan7color: $cyan-7-color;
  cyan8color: $cyan-8-color;
  cyan9color: $cyan-9-color;
  cyan10color: $cyan-10-color;

  lightblue1color: $light-blue-1-color;
  lightblue2color: $light-blue-2-color;
  lightblue3color: $light-blue-3-color;
  lightblue4color: $light-blue-4-color;
  lightblue5color: $light-blue-5-color;
  lightblue6color: $light-blue-6-color;
  lightblue7color: $light-blue-7-color;
  lightblue8color: $light-blue-8-color;
  lightblue9color: $light-blue-9-color;
  lightblue10color: $light-blue-10-color;

  blue1color: $blue-1-color;
  blue2color: $blue-2-color;
  blue3color: $blue-3-color;
  blue4color: $blue-4-color;
  blue5color: $blue-5-color;
  blue6color: $blue-6-color;
  blue7color: $blue-7-color;
  blue8color: $blue-8-color;
  blue9color: $blue-9-color;
  blue10color: $blue-10-color;
  blue11color: $blue-11-color;

  indigo1color: $indigo-1-color;
  indigo2color: $indigo-2-color;
  indigo3color: $indigo-3-color;
  indigo4color: $indigo-4-color;
  indigo5color: $indigo-5-color;
  indigo6color: $indigo-6-color;
  indigo7color: $indigo-7-color;
  indigo8color: $indigo-8-color;
  indigo9color: $indigo-9-color;
  indigo10color: $indigo-10-color;

  deeppurple1color: $deep-purple-1-color;
  deeppurple2color: $deep-purple-2-color;
  deeppurple3color: $deep-purple-3-color;
  deeppurple4color: $deep-purple-4-color;
  deeppurple5color: $deep-purple-5-color;
  deeppurple6color: $deep-purple-6-color;
  deeppurple7color: $deep-purple-7-color;
  deeppurple8color: $deep-purple-8-color;
  deeppurple9color: $deep-purple-9-color;
  deeppurple10color: $deep-purple-10-color;

  bluegrey1color: $blue-grey-1-color;
  bluegrey2color: $blue-grey-2-color;
  bluegrey3color: $blue-grey-3-color;
  bluegrey4color: $blue-grey-4-color;
  bluegrey5color: $blue-grey-5-color;
  bluegrey6color: $blue-grey-6-color;
  bluegrey7color: $blue-grey-7-color;
  bluegrey8color: $blue-grey-8-color;
  bluegrey9color: $blue-grey-9-color;
  bluegrey10color: $blue-grey-10-color;
  bluegrey11color: $blue-grey-11-color;


  grey1color: $grey-1-color;
  grey2color: $grey-2-color;
  grey3color: $grey-3-color;
  grey4color: $grey-4-color;
  grey5color: $grey-5-color;
}
