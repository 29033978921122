#nanoplayer {
  height: 100%;
}

.editor {
  &__container {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
  &__section {
    margin: 0 auto;
    padding-top: 30px;
  }

  &__status-label {
    width: 100%;
    padding: 20px;
    font-size: 18px;
    text-align: center;
  }
}

.fullscreen-bg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 385px;
  overflow: hidden;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  width: 385px;
  margin-left: -11px;
  border-radius: 10px;
  object-fit: cover;
  height: 100%;
}
.quill > .ql-container {
  font-family: inherit;
  font-size: inherit;
  box-sizing: border-box;
  .ql-editor {
    padding: 0;
  }
  .quill-item {
    &.text-center {
      .ql-editor {
        text-align: center;
      }
    }
    &.text-right {
      .ql-editor {
        text-align: right;
      }
    }
    &.text-verdana {
      .ql-editor * {
        font-family: Verdana;
      }
    }
    &.text-times {
      .ql-editor * {
        font-family: Times;
      }
    }
  }

  .settings {
    display: none;
    &__container {
      display: none;
      position: fixed;
      top: 70px;
      right: 0;
      border-left: 1px solid #eaeaea;
      bottom: 0;
      width: 300px;
      background: #fff;
      overflow-y: auto;
      color: #a2a2a2;
      padding-bottom: 300px;
      font-family: 'Roboto';
      .form-select {
        padding: 0;
      }
      .form-switch__label {
        color: inherit;
        font-size: inherit;
      }
      .form-switch__container {
        margin-top: 15px;
      }

      &__top {
        display: inline-block;
        width: 100%;
        background-color: #fcfcfc;
        height: 34px;
        div {
          float: left;
          border-right: 1px solid #eaeaea;
          padding: 8px;
          text-align: center;
          width: 70px;
          cursor: pointer;
          &.active {
            color: #333333;
            background-color: #fff;
            margin-top: 1px;
          }
        }
      }

      &__section {
        width: 100%;
        border-top: 1px solid #eaeaea;
        margin-bottom: 20px;
        font-size: 12px;
        &.collapse {
          border-bottom: 1px solid #eaeaea;
          margin-bottom: 0;
          .content {
            display: none;
          }
          .header {
            background: #fcfcfc;
            border-bottom: 0;
          }
        }
        .header {
          padding: 20px;
          cursor: pointer;
          &.expand {
            background: #fff;
          }
          &.collapse:hover {
            background: #ccc;
          }
        }
        .content {
          margin-top: -1px;
          padding-left: 6px;
          padding: 0 20px;
          .rw-widget {
            font-size: 11px;
          }
        }
      }
      &__background {
        .remove {
          margin-bottom: 10px;
          svg {
            margin-right: 5px;
          }
          &:hover {
            color: red;
            cursor: pointer;
          }
        }
        .properties {
          display: flex;
          .form-switch__container {
            margin-top: 8px;
          }
          .type {
            width: 120px;
            margin-right: 30px;
          }
        }
        .add-new {
          .txt  {
            margin-top: 5px;
            margin-left: 10px;
            font-size: 12px;
            text-decoration: underline;
            color: #333;
          }
        }
      }
      &__text-size {
        margin-top: 4px;
        display: flex;
        .font-size {
          width: 100px;
        }
      }
      &__text-properties {
        display: flex;
        flex-direction: row;
        .font-family {
          width: 170px;
        }
        .align {
          margin-left: 20px;
          margin-top: -3px;
          .txtAlign {
            display: flex;
          }
          .ico {
            margin-right: 10px;
            margin-top: 5px;
          }
        }
      }
      &__color-picker {
        margin-top: 10px;
        font-size: 12px;
        .picker-placement {
          border: 1px solid #333;
          border-radius: 3px;
          width: 30px;
          height: 20px;
        }
      }
      &__text-size {
        .material-input {
          font-size: 12px;
          margin: 0;
          input {
            padding: 6px 12px 4px 5px;
            font-size: 12px;
          }
        }
        .font-size {
          margin-left: 10px;
        }
        .font-type {
          width: 130px;
        }
      }
      .spacer {
        margin-top: 20px;
        height: 0;
      }
      &__gotonext  {
        margin-top: 20px;
      }
      &__color-picker {
        margin-top: 0px;

        &__text {
          margin-top: 10px;
          &__popup {
            position: absolute;
            z-index: 2;
          }
        }
        &__slide-image-colorpicker {
          margin-top: 4px;
          height: 36px;
          width: 36px;
          border-radius: 36px;
          border: 4px solid #272727;
          background-color: transparent;
        }

        &__bg {
          margin-top: 10px;
          width: 100%;
          flex: 1;
          &__remove {
            float: left;
            margin-left: 40px;
            margin-top: -20px;
          }
          &__popup {
            position: absolute;
            z-index: 2;
          }
        }
      }
      &__text-align {
        margin-top: 20px;
        .txtAlign {
          flex: 1;
          flex-direction: row;
          margin-top: 4px;
          .ico {
            float: left;
            width: 32px;
            background-color: white;
            padding: 3px 10px;
            border: 1px solid #ccc;
          }
        }
      }
    }
  }
}
