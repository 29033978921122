@font-face {
  font-family: 'icomoon';
  src: url('../../../static/fonts/icomoon.eot?1isj1q');
  src: url('../../../static/fonts/icomoon.eot?1isj1q#iefix') format('embedded-opentype'), url('../../../static/fonts/icomoon.woff2?1isj1q') format('woff2'), url('../../../static/fonts/icomoon.ttf?1isj1q') format('truetype'), url('../../../static/fonts/icomoon.woff?1isj1q') format('woff'), url('../../../static/fonts/icomoon.svg?1isj1q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e907";
}

.icon-laptop:before {
  content: "\e908";
}

.icon-tablet:before {
  content: "\e909";
}

.icon-add:before {
  content: "\e900";
}

.icon-done:before {
  content: "\e901";
}

.icon-home:before {
  content: "\e902";
}

.icon-photo-library:before {
  content: "\e903";
}

.icon-image:before {
  content: "\e904";
}

.icon-reorder:before {
  content: "\e904";
}

.icon-trash:before {
  content: "\e905";
}

.icon-x:before {
  content: "\e906";
}
