@import '~react-toastify/scss/main.scss';
@import '~semantic-ui-css/semantic.min.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-widgets/dist/css/react-widgets.css';

@import 'utils/mixins';
@import 'utils/variables';

@import 'base/base';
@import 'base/btn';
@import 'base/checkbox';
@import 'base/common';
@import 'base/fonts';
@import 'base/icons';
@import 'base/input';
@import 'base/normalize';
@import 'base/radio';
@import 'base/tabs';

@import 'components/app';
@import 'components/CourseDetailsPage';
@import 'components/CourseHeader';
@import 'components/CourseSectionsEditors';
@import 'components/CourseTemplateSelection';
@import 'components/FilePreview';
@import 'components/FormSwitch';
@import 'components/Player';
@import 'components/PlayerCourseCover';
@import 'components/PlayerCoursePage';
@import 'components/PlayerDndSelect';
@import 'components/PlayerFilePreview';
@import 'components/PlayerParagraph';
@import 'components/PlayerPicture';
@import 'components/PlayerPictureSlides';
@import 'components/PlayerPointsColumn';
@import 'components/PlayerPreviewModal';
@import 'components/PlayerQuestionnaire';
@import 'components/PlayerSpeedCourse';
@import 'components/PlayerVideo';
@import 'components/Sidebar';
@import 'components/Pages';
body {
  display: flex;
  flex-direction: column;
}
