.course-miniatures {
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 !important;
  }

  &__container {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
  }

  &__action-buttons-container {
    display: flex;
    justify-content: flex-end;
  }
}

.course-miniature {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 200px;
    margin: 20px;
    padding: 10px;
    border-radius: 7px;
    background-color: white;
    box-shadow: $box-shadow;
    color: $blue-grey-11-color;
    transition: background-color .3s;
    cursor: pointer;
    &:hover {
      background-color: $blue-grey-1-color;
    }
  }

  &__image {
    flex: 0 0 130px;
  }

  &__title {
    flex: 0 0 auto;
    width: 180px;
    padding: 10px 0;
    line-height: 20px;
    text-align: center;
  }
}
