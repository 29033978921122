.page-text-content {
  h1.title{
    font-size: 32px;
    text-align: center;
    line-height: 120%;
  }
  .ingress{
    font-weight: 600;
  }
}
.page-text-dilemma {
  justify-content: center;
}
.page-text-questionnaire, .page-text-picturesquestionnaire {
  &.answers {
    justify-content: center;
    text-align: center;
    &.right {
      background-color: #237804;
      color: white;
    }
    &.wrong {
      background-color: #a8071a;
      color: white;
    }
  }

}
