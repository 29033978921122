.form-select {
  padding: 10px;

  &__label {
    padding: 0 4px;
    line-height: 24px;
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0;

    .rw-state-focus > &,
    .rw-state-focus > &:hover {
      box-shadow: none;
      border: 1px solid grey;
    }

    &--error {
      border-color: #c22;
      color: #c22;
    }
  }

  .rw-list-option {
    &.rw-state-focus {
      border-color: grey;
    }

    &:hover {
      background: mix(white, grey, 90%);
    }
  }

  &__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
