.player-course-details {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 70px;
    z-index: 1001;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    background: linear-gradient(#565656 0%, #454545 59.11%, #3a3a3a 74.69%, #000 100%);
    color: white;
  }

  &__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: hidden;
  }

  &__content-container {
    flex: 0 1 auto;
    width: 100%;
    overflow: auto;
  }

  &__title,
  &__subtitle {
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    white-space: pre-wrap;
  }

  &__title {
    font-size: 30px;
    line-height: 36px;
  }

  &__subtitle {
    padding-bottom: 70px;
    font-size: 18px;
    line-height: 21px;
  }

  &__nav-bar {
    position: absolute;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;

    &::before {
      content: '';
      display: block;
      flex: 0 0 50%;
      background: $button-start-text-color;
    }
  }
  &__start-button {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: $mobile-small) {
  .player-course-details__subtitle {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: $mobile-small + 1) and (max-width: $mobile) {
  .player-course-details__subtitle {
    padding-bottom: 40px;
  }
}
