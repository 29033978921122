.course-details-page {
  &__container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
  }
  &__content {
    width: 100%;
    height: 100%;
    max-width: 800px;
    &.full {
      max-width: 100%;
    }
  }
  &__points-section {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding-top: 0;
  }
  &__input-wrapper {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
  }
  &__dropzone-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 320px;
    padding: 10px;
  }
  &__cover-file-status {
    width: 100%;
    border-width: 2px;
    padding: 40px 20px;
    height: 200px;
    border-radius: 5px;
    background-color: #fafafa;
    border: 1px dotted #ccc;
    text-align: center;
  }
  &__dropzone-image {
    height: 240px;
    width: 100%;
  }
  &__dropzone-buttons {
    flex: 0 0 60px;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    button {
      height: 40px;
    }
  }
  &__section-header {
    align-self: flex-start;
    margin: 10px;
    padding-left: 5px;
    color: $blue-grey-11-color;
    font-size: 16px;
  }
  &__section {
    width: 100%;
  }
}

.wide-pages-list {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    margin: 10px 0 40px 0;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-direction: row;
    margin: 10px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: $box-shadow;
    background: white;
  }

  &__page-drag-handle {
    flex: 0 0 auto;
    margin-left: -10px;
    margin-right: 10px;
    .icon-reorder {
      color: #ccc;
    }
  }

  &__page-title-container {
    display: flex;
    flex: 1 1 100%;
    min-width: 0;
    max-width: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-left: 10px;
  }

  &__page-title-main {
    font-size: 1.2em;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__page-title-secondary {
    color: $blue-grey-9-color;
    font-size: 1em;
  }

  &__page-action-buttons {
    display: flex;
    flex: 0 0 auto;
    justify-content: stretch;
  }

  &__action-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;
  }
}
