.sidebar {
  &__container {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 20px;
    width: $sidebar-width;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  &__add-new-slide {
    width: 100px;
    height: 80px;
    margin-top: 20px;
    margin-left: 20px;
    float: left;
  }
  &__container__sub {
    left: $sidebar-width;
    width: 120px;
    z-index: 1009;
    height: 100%;
    margin-left: 30px;
    pointer-events: none;
    .sidebar__page-preview {
      width: 100px !important;
      height: 80px !important;
      color: gray !important;
      font-size: 22px !important;
      padding-top: 4px !important;
    }
    .sidebar__page-details,
    .sidebar__page-text {
      display: none !important;
    }
  }

  &__pages-list {
    float: left;
  }

  &__speed-course-wrapper {
    padding: 10px 0;
    text-align: center;
    &.active {
      background: white;
    }
    &:focus {
      outline: none;
    }
  }
  &__page-wrapper {
    float: left;
    text-align: center;
    pointer-events: auto;
    &.active {
      .sidebar__page-preview {
        background: white;
        border: 1px solid #5cabec;
      }
    }
    &:hover {
      .sidebar__page-drag-handle {
        display: block;
      }
    }
    &:focus {
      outline: none;
    }
  }
  &__home-button {
    padding-top: 10px;
    padding-bottom: 18px;
    line-height: 22px;
    padding-top: 10px;
    background-color: #efefef;
    cursor: pointer;
    height: 70px;
    &.active {
      color: #f1f7ff;
      background-color: transparent !important;
    }
  }

  &__page-preview {
    position: relative;
    display: block;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    outline: none;
    padding: 10px 0;
    width: 135px;
    border: 1px solid #ccc;
    height: 90px;
    border-radius: 10px;
    margin: 20px;
    margin-bottom: 4px;
    background: white;
  }

  &__page-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #ebebeb;
    margin: 0 auto;
    height: 86%;
    width: 30%;
    margin-top: 2%;
    border-radius: 4px;
  }

  &__page-details {
    margin-left: 20px;
    text-align: left;
  }

  &__page-number,
  &__page-text {
    padding: 3px;
    text-align: left;
    color: gray;
    float: left;
    font-size: 0.8em;
    line-height: 0.8em;
  }

  &__page-drag-handle {
    position: absolute;
    bottom: 7px;
    right: -24px;
    padding: 3px;
    display: none;
    color: gray;
    font-size: 0.5em;
    line-height: 0.5em;
    cursor: -webkit-grab;
    i.normal {
      font-size: 16px !important;
    }
  }

  &__add-new-nano {
    background-color: #e8e8e8;
    display: inline-flex;
    margin-top: 20px;
    border-radius: 10px;
    height: 100px;
    width: 140px;
    margin-bottom: 50px;
    margin-left: 17px;
  }

  &__add-page-button {
    color: #4c4c4c;
    height: 100%;
    border-radius: 10px;
    background-color: #e8e8e8;
    width: 100%;
    padding: 0;
    font-size: 24px;
    border: 0;
    text-align: center;
    vertical-align: center;
    font-size: 12px;
    pointer-events: auto;
    .shortcut {
      font-weight: 600;
      color: black;
      font-size: 14px;
    }
    &:hover {
      cursor: pointer;
      &:after,
      &:before {
        background-color: white !important;
      }
    }
    &--small {
      font-size: 12px;
    }
    &--large {
      font-size: 22px;
    }
  }
}
