@mixin SlideTitle() {
  font-size: 15px;
  line-height: 16px;
  color: $text-color;

  @media screen and (max-width: $tablet) {
    font-size: 15px;
    line-height: 16px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 14px;
    line-height: 16px;
  }

  @media screen and (max-width: $mobile-small) {
    font-size: 13px;
    line-height: 15px;
  }
}

@mixin SlideSubtitle() {
  font-size: 14px;
  line-height: 15px;
  color: $text-color;

  @media screen and (max-width: $tablet) {
    font-size: 14px;
    line-height: 15px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 13px;
    line-height: 14px;
  }

  @media screen and (max-width: $mobile-small) {
    font-size: 12px;
    line-height: 13px;
  }
}


.player-slider {
  &__wrapper {
    padding: 0 $default-side-padding;
  }

  &__container {
    position: relative;
    margin: 0 50px 30px 50px;
  }

  &__slide-container {
    position: relative;
    height: 400px;
    &:focus {
      outline: none;
    }
  }
  &__slide-image {
    height: 300px;
    width: 300px;
    padding: 15px;
    img {
      height: 100%;
    }
    &:focus {
      outline: none;
    }
  }
  &__no-file {
    width: 200px;
    height: 100%;
    padding: 20px;
    border: 2px solid $highlight-color;
  }
  &__slide-description-container {
    position: absolute;
    top: 300px;
    width: 100%;
    height: 100px;
    padding: 0 15px;
    overflow: hidden;
  }
  &__slide-title {
    @include SlideTitle();
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__slide-subtitle {
    @include SlideSubtitle();
    width: 100%;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__slider-arrow {
    &.slick-arrow.slick-next:before,
    &.slick-arrow.slick-prev:before {
      color: $highlight-color;
    }
  }

  &__single-slide-container {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }

  &__no-image {
    padding: $default-side-padding;
    &::after {
      content: "No file provided.";
      display: block;
      width: 100%;
      height: 150px;
      padding: $default-side-padding;
      border: 3px dashed $text-color;
      color: $text-color;
      text-align: center;
    }
  }

  &__single-slide-title {
    @include SlideTitle();
    margin-top: 10px;
    padding: 0 $default-side-padding;
  }

  &__single-slide-subtitle {
    @include SlideSubtitle();
    margin-top: 7px;
    padding: 0 $default-side-padding;
  }
}

@media screen and (max-width: $tablet) {
  .player-slider {
    &__slide-container {
      height: 370px;
    }
    &__slide-image {
      height: 270px;
      width: 270px;
    }
    &__slide-description-container {
      top: 270px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .player-slider {
    &__slide-container {
      height: 300px;
    }
    &__slide-image {
      height: 200px;
      width: 200px;
      padding: 10px;
    }
    &__slide-description-container {
      top: 200px;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: $mobile-small) {
  .player-slider {
    &__slide-container {
      height: 230px;
    }
    &__slide-image {
      height: 130px;
      width: 130px;
      padding: 5px;
    }
    &__slide-description-container {
      top: 130px;
      padding: 0 5px;
    }
  }
}

@media screen and (max-width: 850px) {
  .player-slider {
    &__wrapper {
      padding: 0 $default-mobile-side-padding;
    }
    &__container {
      margin: 0;
    }
    &__no-image {
      padding: $default-mobile-side-padding;
      &::after {
        padding: $default-mobile-side-padding;
      }
    }
    &__single-slide-title {
      padding: 0 $default-mobile-side-padding;
    }
    &__single-slide-subtitle {
      padding: 0 $default-mobile-side-padding;
    }
  }
}
