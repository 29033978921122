.player-video {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__video {
    width: 100%;
  }
  &__no-video-info {
    width: 100%;
    height: 150px;
    border: 1px solid $blue-grey-5-color;
    border-radius: 5px;
    text-align: center;
    line-height: 150px;
  }

  &__description-container {
    margin: 15px 0;
    padding: 0 $default-side-padding;
  }

  &__title {
    margin: 10px 0;
    font-size: 18px;
    line-height: 21px;
  }

  &__subtitle {
    margin: 10px 0;
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 850px) {
  .player-video {
    &__description-container {
      padding: 0 $default-mobile-side-padding;
    }
  }
}
