.btn {
  // position: relative;
  overflow: hidden;
  display: block;
  height: 41px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 12px;
  border-width: 0;
  border-radius: 5px;
  outline: none;
  background-color: $blue-grey-11-color;
  box-shadow: $box-shadow;
  color: white;
  transition: background-color .3s;
  &:hover:enabled {
    background-color: $blue-grey-10-color;
  }
  &:disabled {
    box-shadow: none;
    background: $blue-grey-3-color;
  }
}

.btn-icon {
  padding: 8px 13px;
}

.btn-transparent {
  background-color: transparent;
  box-shadow: none;
  color: $blue-grey-11-color;
  &:hover:enabled {
    background-color: rgba(168, 168, 168, 0.3);
  }
  &.no-hover:hover {
    background-color: transparent;
  }
}

.btn-start {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: $button-start-background-color;
  color: $button-start-text-color;
  font-size: $nav-bar-font-size;
  &:hover:enabled {
    background-color: $button-start-background-color;
  }
}

.btn-continue {
  height: $nav-bar-height;
  padding: 0 30px;
  border-radius: 0;
  background-color: $button-continue-background-color;
  color: $button-continue-text-color;
  font-size: $nav-bar-font-size;
  text-align: center;
  &:disabled {
    color: $button-continue-disabled-text-color;
    background-color: $button-continue-background-color;
  }
  &:hover:enabled {
    background-color: $button-continue-background-color;
  }
}

.btn-finalize {
  height: $nav-bar-height;
  padding: 0 30px;
  border-radius: 0;
  background-color: $finalize-button-background-color;
  color: -text-color;
  font-size: $nav-bar-font-size;
  text-align: center;
  &:hover:enabled {
    background-color: $finalize-button-background-color;
  }
}

.btn-blue {
  background-color: $btn-blue;
  color: white;
  &:hover:enabled {
    background-color: $blue-10-color;
  }
}

.btn-positive {
  color: $green-6-color;
  background-color: white;
  border: 2px solid white!important;
  box-shadow: none!important;
  &:hover:enabled {
    border: 2px solid $green-7-color!important;
    background-color: white;
  }
}

.btn-negative {
  background-color: white;
  color: $red-6-color;
  border: 2px solid white!important;
  box-shadow: none!important;
  &:hover:enabled {
    background-color: white;
    border: 2px solid $red-7-color!important;
  }
}
