.file-preview {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 160px;
  }

  &__file-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $grey-3-color;
    &--cover {
      background-size: cover;
    }
  }

  &__file-as-element-container {
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__image-as-element {
    // width: 100%;
  }

  &__video {
    max-width: 100%;
    height: 100%;
  }

  &__no-file-info {
    width: 100%;
    height: 300px;
    border-radius: 7px;
    font-size: 18px;
    text-align: center;
    line-height: 300px;
  }

  &__delete {
    $delete-size: 30px;
    $x-width: 2px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px #ccc;
    &:before,
    &:after {
      position: absolute;
      top: ($delete-size / 2) - ($x-width / 2);
      left: 8px;
      content: ' ';
      width: $delete-size - 16px;
      height: $x-width;
      background-color: white;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    position: absolute;
    top: -1 * $delete-size / 2;
    right: -1 * $delete-size / 2;
    width: $delete-size;
    height: $delete-size;
    padding: 0;
    border: none;
    border-radius: $delete-size;
    background: $red-7-color;
    color: white;
    outline: none;
    line-height: $delete-size;
    text-align: center;
  }
}
