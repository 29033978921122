.player-course-page {
  &__wrapper {
    width: 100%;
    height: 100%;
    background: $background-color;
    padding-bottom: $nav-bar-height;
  }

  &__content-container {
    position: absolute;
    top: 0;
    bottom: $nav-bar-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: auto;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
    width: 100%;
    overflow: auto;
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    min-height: 0;
    margin: auto;
  }

  &__sections {
    flex: 1 1 auto;
    overflow-y: auto;
    &:nth-child(2) {
      -ms-flex: 1 1 750px;
      max-width: calc(100% - 50px);
      padding: 0;
    }
    padding-bottom: 40px;
  }

  &__points-bar {
    flex: 0 0 auto;
  }

  &__title {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0 $default-mobile-side-padding;
    font-size: 20px;
    line-height: 28px;
    color: $highlight-color;
    &--centered {
      text-align: center;
    }
  }

  &__subtitle {
    margin-bottom: 20px;
    padding: 0 $default-mobile-side-padding;
    font-size: 14px;
    line-height: 22px;
    &--centered {
      text-align: center;
    }
    font-size: 13px;
    line-height: 120%;
    h1 {
      padding-top: 12px;
      font-size: 80px;
      margin-bottom: 12px;
    }
  }

  &__continue-button-container {
    display: flex;
    justify-content: center;
  }

  &__nav-bar {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: $nav-bar-height;
  }

  &__progress-bar {
    &-container {
      position: relative;
      z-index: 1000;
      flex: 1 1 auto;
      height: $nav-bar-height;
      background: $button-continue-background-color;
    }
    &-column {
      position: absolute;
      min-width: 100px;
      z-index: 1001;
      top: 0;
      left: 0;
      height: $nav-bar-height;
      width: 0;
      background: $progress-bar-color;
      transition: width 0.3s;
    }
    &-text {
      position: absolute;
      z-index: 1002;
      top: 0;
      left: 0;
      height: $nav-bar-height;
      padding: 0 25px;
      font-size: $nav-bar-font-size;
      line-height: $nav-bar-height;
      color: $button-continue-background-color;
    }
  }

  &__continue-button {
    display: flex;
    flex: 0 0 33%;
    flex-direction: column;
    align-items: stretch;
  }

  &__finalize-button {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: stretch;
  }
}

.player-course-result {
  .player-course-page {
    &__sections {
      width: 320px;
      font-size: 12px;
      height: 320px;
      border-radius: 320px;
      margin-top: 20px;
      background-color: white;
      box-shadow: 0px 0px 12px rgba(39, 39, 39, 0.3);
    }
    &__title {
      margin-top: 40px;
    }
  }
}

@media screen and (min-width: 850px) {
  .player-course-page {
    &__content {
      background: white;
      border-radius: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      box-shadow: $box-shadow;
    }
    &__sections {
      &:nth-child(2) {
        padding: 25px;
        -ms-flex: 1 1 735px;
        max-width: calc(100% - 65px);
      }
    }
    &__title,
    &__subtitle {
      padding: 0 $default-side-padding;
    }
  }
  .player-course-page__subtitle h1 {
    font-size: 100px;
  }
  .player-course-page__subtitle {
    font-size: 16px;
  }
  .player-course-result {
    .player-course-page {
      &__content {
        font-size: 16px;
        width: 420px;
        height: 420px;
        border-radius: 420px;
        margin-top: 50px;
      }
      &__title {
        margin-top: 70px;
      }
    }
  }
}
