.player-file-preview {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &--cover {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &--greyscale {
      position: relative;
      @include grayscale_element(50%);
      transition: all 200ms ease-in-out;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($color: #928989, $alpha: 0.7)
      }
    }
  }
  &__video {
    width: 100%;
    height: 100%;
  }

  &__cover-video-wrapper {
    position: absolute;
    top: -200%;
    left: -200%;
    width: 500%;
    height: 500%;
    z-index: 1000;
  }

  &__cover-video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: 20%;
    margin: auto;
    min-width: 20%;
  }

  &__no-file {
    width: 100%;
    height: 100%;
    text-align: center;
    border: 3px dashed $text-color;
    border-radius: 4px;
  }
}
