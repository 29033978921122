.course-header {
  &__container {
    display: flex;
    align-items: center;
    height: 73px;
    margin-bottom: 20px;
    padding: 15px;
    border-bottom: 1px solid $grey-3-color;
    color: $blue-grey-11-color;
  }

  &__course-name {
    flex: 1 1 auto;
    font-size: 1.75em;
    line-height: 1.75em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__action-buttons {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
  }

  &__action-button {
    height: 40px;
    margin-left: 10px;
  }
}
